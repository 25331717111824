import ModalWithChildren from "./ModalWithChildren";
import { languageOptions } from "../utils";


const renderLanguageModal = (modalOpenLanguage, setModalOpenLanguage, targetLanguage, handleLanguageChange) => {
    return (
      <ModalWithChildren isOpen={modalOpenLanguage} onClose={() => setModalOpenLanguage(false)}>
        <div className="flex flex-col items-center">
          <h2 className="mb-4">Selecciona el idioma:</h2>
          <select id="language-select" value={targetLanguage} onChange={handleLanguageChange}>
            {languageOptions.map(option => (
              <option key={option.language} value={option.language}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </ModalWithChildren>
    );
  }

  export default renderLanguageModal;