import React from 'react';
import PropTypes from 'prop-types';

const ModalWithChildren = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-60 bg-black bg-opacity-50">
            <div className="bg-white rounded-lg shadow-lg">
                <div className="p-4">
                    {children}
                </div>
                <div className="p-4 flex justify-end">
                    <button
                        className="px-4 py-1 bg-primary text-white rounded-md"
                        onClick={onClose}
                    >
                        Cerrar
                    </button>
                </div>
            </div>
        </div>
    );
};

ModalWithChildren.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
};

export default ModalWithChildren;