import axios from 'axios';

const API_KEY = process.env.REACT_APP_GCP_API_KEY;
const TRANSLATION_URL = process.env.REACT_APP_GCP_TRANSLATION_URL;

async function fetchTranslation(text, targetLanguage) {
    try {
        const response = await axios.post(TRANSLATION_URL, null, {
            params: {
                q: text,
                target: targetLanguage,
                key: API_KEY,
            },
        });
        return response.data.data.translations[0].translatedText;
    } catch (error) {
        console.error('Error translating text:', error);
        throw new Error('Failed to translate text');
    }
}

export { fetchTranslation };