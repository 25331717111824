import { SelectMediaDevicesModal } from 'react-select-media-devices-modal';


const renderSelectMediaDevicesModal = (modalOpen, handleDeviceSelected, handleDeviceSelectCanceled) => {
    return (
        <div className='fixed z-60'>
            <SelectMediaDevicesModal
                isSelectAudioInput
                isSelectAudioOutput
                isSelectVideoInput={false}
                open={modalOpen}
                audioInputDeviceLabel="Audio input device"
                audioOutputDeviceLabel="Audio output device"
                confirmButtonText="Confirm"
                cancelButtonText="Cancel"
                allowOutsideClick={true}
                onDeviceSelected={handleDeviceSelected}
                onDeviceSelectCanceled={handleDeviceSelectCanceled}
                zIndex={60}
            />
        </div>
    );
}


export default renderSelectMediaDevicesModal;