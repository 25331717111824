import React, { useCallback, useEffect, useState } from 'react';
import useSpeechToText from 'react-hook-speech-to-text';
import { languageOptions } from '../utils';
import { useTranslateAndFetchAudio, usePlayAudioQueue } from '../hooks';
import { RenderTranslatedResults, DockNavbar } from '.';

const ListenComponent = () => {
    const {
        error,
        interimResult,
        isRecording,
        results,
        startSpeechToText,
        stopSpeechToText,
    } = useSpeechToText({
        continuous: true,
        useLegacyResults: false,
    });
    const [triggerRipple, setTriggerRipple] = useState(false);
    const [translatedResults, setTranslatedResults] = useState(results.map(result => ({ ...result, played: false })));
    const [audioQueue, setAudioQueue] = useState([]);
    const [isPlaying, setIsPlaying] = useState(false);
    const [deviceInput, setDeviceInput] = useState(null);
    const [deviceOutput, setDeviceOutput] = useState(null);
    const [targetLanguage, setTargetLanguage] = useState(languageOptions[0].language);
    const [targetLanguageRegion, setTargetLanguageRegion] = useState(languageOptions[0].region);
    const [targetVoice, setTargetVoice] = useState(languageOptions[0].voice);

    const handleTriggerRipple = () => {
        setTriggerRipple(!triggerRipple);
    }

    useTranslateAndFetchAudio(handleTriggerRipple, results, setTranslatedResults, setAudioQueue, targetLanguage, targetLanguageRegion, targetVoice);
    usePlayAudioQueue(audioQueue, setAudioQueue, setTranslatedResults, isPlaying, setIsPlaying, deviceOutput);

    const handleRecordingToggle = useCallback(() => {
        if (isRecording) {
            stopSpeechToText();
        } else {
            startSpeechToText();
        }
    }, [isRecording, startSpeechToText, stopSpeechToText]);

    // Cuando el usuario hable por el microfono ejecutar handleTriggerRipple
    useEffect(() => {
        if (interimResult) handleTriggerRipple();
    }, [interimResult]);

    if (error) return <p>Web Speech API is not available in this browser 🤷‍</p>;

    return (
        <>
        {/* Texto en grande con el ultimo translatedResults[i].translatedText */}
        <div className="flex items-center justify-center h-screen">
            <h1 className="text-6xl font-bold text-text dark:text-text-dark">
                {translatedResults[translatedResults.length - 1]?.translatedText}
            </h1>
        </div>

        <DockNavbar 
            handleRecordingToggle={handleRecordingToggle} 
            inputText={interimResult}
            setDeviceInput={setDeviceInput} 
            setDeviceOutput={setDeviceOutput} 
            targetLanguage={targetLanguage} 
            setTargetLanguage={setTargetLanguage}
            setTargetLanguageRegion={setTargetLanguageRegion}
            setTargetVoice={setTargetVoice}
        />

        </>
    );
};

export default ListenComponent;