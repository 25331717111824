
async function playTranslatedAudio({ audioContent, deviceOutput }) {
    try {
        if (audioContent) {
            const audio = new Audio(`data:audio/mp3;base64,${audioContent}`);
            if (deviceOutput && typeof audio.setSinkId !== 'undefined') await audio.setSinkId(deviceOutput.deviceId);
            return new Promise((resolve, reject) => {
                audio.onended = resolve;
                audio.onerror = reject;
                audio.play();
            });
        } else {
            throw new Error('No se pudo obtener el contenido de audio.');
        }
    } catch (error) {
        console.error('Error playing audio:', error);
        throw error;
    }
}

export default playTranslatedAudio;