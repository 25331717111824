import React, { useState } from 'react';
import { TbSettings, TbLanguage, TbMicrophone } from "react-icons/tb";
import { languageOptions } from "../utils";
import { renderLanguageModal, renderSelectMediaDevicesModal } from '.';


const Dockbar = ({ handleRecordingToggle, inputText, setDeviceInput, setDeviceOutput, targetLanguage, setTargetLanguage, setTargetLanguageRegion, setTargetVoice }) => {

  const [modalOpen, setModalOpen] = useState(false);

  const [modalOpenLanguage, setModalOpenLanguage] = useState(false);

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    const selectedOption = languageOptions.find(option => option.language === selectedLanguage);
    setTargetLanguage(selectedOption.language);
    setTargetLanguageRegion(selectedOption.region);
    setTargetVoice(selectedOption.voice);
    setModalOpenLanguage(false);
  };

  const handleDeviceSelected = (devices) => {
    setModalOpen(false);
    setDeviceInput(devices.audioInput);
    setDeviceOutput(devices.audioOutput);
    console.log(devices);
  };

  const handleDeviceSelectCanceled = () => {
    setModalOpen(false);
  };

  return (
    <>

      {/* Componente de SelectMediaDevices */}
      {renderSelectMediaDevicesModal(modalOpen, handleDeviceSelected, handleDeviceSelectCanceled)}

      {/* Componente de Select lenguaje */}
      {renderLanguageModal(modalOpenLanguage, setModalOpenLanguage, targetLanguage, handleLanguageChange)}

      {/* Componente del Dock */}
      <div className='absolute bottom-0 pb-4 w-full flex justify-center'>
        <div className='w-8/12 relative rounded-full h-16 bg-secondary bg-opacity-45 shadow-lg backdrop-blur-lg flex items-center'>
          {/* Contenedor de los iconos alineados a la izquierda */}
          <div className='absolute p-2 h-full flex items-center space-x-4'>
            <button
              onClick={() => setModalOpen((current) => !current)}
              className='rounded-full h-full w-auto p-2 flex justify-center items-center
                        bg-secondary text-background-body dark:background-body-dark hover:text-primary 
              '>
              <TbSettings className=' w-full h-full' />
            </button>

            <button
              onClick={() => setModalOpenLanguage((current) => !current)}
              className='rounded-full h-full w-auto p-2 flex justify-center items-center
                        bg-secondary text-background-body dark:background-body-dark hover:text-primary 
              '>
              <TbLanguage className=' w-full h-full' />
            </button>
          </div>
          {/* Contenedor del texto centrado */}
          <div className='h-full w-full p-2 flex justify-center'>
            <div className='w-8/12 h-full rounded-full bg-primary content-center p-1 flex justify-between items-center'>
              <h2 className='font-medium text-lg pl-2'>{inputText}</h2>
              <button
                onClick={() => handleRecordingToggle()}
                className='rounded-full h-full w-auto p-2 flex justify-center items-center
                  bg-secondary text-background-body dark:background-body-dark hover:text-primary'>
                <TbMicrophone className='w-full h-full' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>

  );
};

export default Dockbar;


