import { useEffect } from 'react';
import { playTranslatedAudio } from '../hooks';

const usePlayAudioQueue = (audioQueue, setAudioQueue, setTranslatedResults, isPlaying, setIsPlaying, deviceOutput) => {
    useEffect(() => {
        if (!isPlaying && audioQueue.length > 0) {
            const nextAudio = audioQueue[0];
            setIsPlaying(true);
            playTranslatedAudio({ audioContent: nextAudio, deviceOutput })
                .then(() => {
                    setAudioQueue(queue => queue.slice(1));
                    setTranslatedResults(results => results.map(result =>
                        result.translatedText === nextAudio ? { ...result, played: true } : result
                    ));
                    setIsPlaying(false);
                })
                .catch((error) => {
                    console.error('Error playing audio:', error);
                    setIsPlaying(false);
                });
        }
    }, [audioQueue, isPlaying, setAudioQueue, setTranslatedResults, setIsPlaying]);
};

export default usePlayAudioQueue;