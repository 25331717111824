import axios from 'axios';

const apiKey = process.env.REACT_APP_GCP_API_KEY;
const url = `${process.env.REACT_APP_GCP_SPEECH_URL}?key=${apiKey}`;

export async function fetchAudioContent(text, targetLanguageRegion, targetVoice) {
    const requestBody = {
        input: { text },
        voice: { languageCode: targetLanguageRegion, name: targetVoice },
        audioConfig: { audioEncoding: 'MP3' },
    };

    try {
        const response = await axios.post(url, requestBody, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data.audioContent;
    } catch (error) {
        console.error('Error:', error);
        return '';
    }
}