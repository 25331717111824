import { useEffect, useCallback } from 'react';
import { fetchAudioContent, fetchTranslation } from '../services';
import { replaceSmartQuotes } from '../utils';

const useTranslateAndFetchAudio = (handleTriggerRipple, results, setTranslatedResults, setAudioQueue, targetLanguage = 'en', targetLanguageRegion, targetVoice) => {
    const translateAndQueue = useCallback(async (finalText) => {
        try {
            const preTranslatedText = await fetchTranslation(finalText, targetLanguage);
            const translatedText = replaceSmartQuotes(preTranslatedText);
            const audioContent = await fetchAudioContent(translatedText, targetLanguageRegion, targetVoice);
            const updatedResults = [...results];
            updatedResults[results.length - 1] = { ...updatedResults[results.length - 1], translatedText, audioContent, played: false };
            setTranslatedResults(updatedResults);
            setAudioQueue(queue => [...queue, audioContent]);
        } catch (error) {
            console.error('Error translating text:', error);
        }
    }, [results, setTranslatedResults, setAudioQueue, targetLanguage]);

    useEffect(() => {
        if (results.length > 0) {
            handleTriggerRipple();
            const finalText = results[results.length - 1].transcript;
            translateAndQueue(finalText);
        }
    }, [results, translateAndQueue]);
};

export default useTranslateAndFetchAudio;